@if (input.secure) {
  <div
    [innerHTML]="
      _sanitizer.bypassSecurityTrustHtml(input.html | parse: context | async)
    "
    class="style"
  ></div>
} @else {
  <div [innerHTML]="input.html | parse: context | async" class="style"></div>
}
