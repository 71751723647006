import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { TexthtmlInput, TexthtmlOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { AsyncPipe } from '@angular/common';

@Register('Texthtml')
@Component({
  selector: 'tb-texthtml',
  templateUrl: './texthtml.component.html',
  styleUrls: ['./texthtml.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // Widget should be optimised for performance
  encapsulation: ViewEncapsulation.None, // Disabling view encapsulation allows for easier styling
  standalone: true,
  imports: [AsyncPipe, ParsePipe],
})
export class TexthtmlComponent
  extends BaseWidgetComponent<TexthtmlInput, TexthtmlOutput>
  implements OnInit, OnDestroy {}
