import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Register } from '@app/utils/type-registry';
import { ChipGridInput, ChipGridOutput } from '@trackback/widgets';
import { BaseWidgetComponent } from '../base-widget.component';
import { NgClass, NgForOf } from '@angular/common';
import { MatLegacyChipsModule } from '@angular/material/legacy-chips';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { MatIconModule } from '@angular/material/icon';

@Register('ChipGrid')
@Component({
  selector: 'tb-chip-grid',
  templateUrl: './chip-grid.component.html',
  styleUrls: ['./chip-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatLegacyChipsModule,
    NgForOf,
    DynamicWidgetDirective,
    NgClass,
    MatIconModule,
  ],
})
export class ChipGridComponent
  extends BaseWidgetComponent<ChipGridInput, ChipGridOutput>
  implements OnInit, OnDestroy {}
