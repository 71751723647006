import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, importProvidersFrom, isDevMode } from '@angular/core';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarModule as MatSnackbarModule,
} from '@angular/material/legacy-snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { appInitialiserFactory } from '@app/app-initialiser.factory';
import { AppComponent } from '@app/app.component';
import { DynamicWidgetPageComponent } from '@app/components/dynamic-widget-page.component';
import { DefaultConfigResolverGuard } from '@app/guards/default-config-resolver.guard';
import { AuthCookieInterceptor } from '@app/interceptors/auth-cookie.interceptor';

import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NGXS_PLUGINS, NgxsModule, Store } from '@ngxs/store';
import { DispatcherService } from '@app/services/dispatcher.service';
import { NgxsWidgetPluginService } from '@app/services/ngxs-widget-plugin.service';
import { ACTION_DISPATCHER } from '@app/models/action-dispatcher.model';
import { WidgetsState } from '@app/state/widgets/widgets.state';
import '@app/widgets-registry';
import {
  DEFAULT_FALLBACK_LANGUAGE_CODE,
  TranslationState,
} from '@app/state/translations/translation.state';
import { LayoutState } from '@app/state/layout/layout.state';
import {
  APP_CONFIG,
  AppConfigModel,
  getApiUrl,
} from '@app/models/app-config.model';
import dateFnsLocales from '@app/models/date-fns-locales';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

bootstrapApplication(AppComponent, {
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_CONFIG,
      useValue: {
        ...environment,
        API_URL: getApiUrl(
          location.hostname,
          environment.APP_ID || 0,
          environment.BASE_HREF || '/'
        ),
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useFactory: (config: AppConfigModel) =>
        ({
          duration: config.DEFAULT_NOTIFICATION_DURATION,
        }) as MatSnackBarConfig,
      deps: [APP_CONFIG],
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: appInitialiserFactory,
      deps: [Store, DateAdapter],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthCookieInterceptor,
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: dateFnsLocales[DEFAULT_FALLBACK_LANGUAGE_CODE],
    },
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_DATE_FNS_FORMATS,
    },
    {
      provide: ACTION_DISPATCHER,
      useClass: DispatcherService,
    },
    {
      provide: NGXS_PLUGINS,
      useClass: NgxsWidgetPluginService,
      multi: true,
    },
    importProvidersFrom(HttpClientModule),
    provideRouter(
      [
        {
          path: '',
          redirectTo: 'login',
          pathMatch: 'full',
        },
        {
          path: '**',
          component: DynamicWidgetPageComponent,
          runGuardsAndResolvers: 'always',
          resolve: {
            widget: DefaultConfigResolverGuard,
          },
        },
      ],
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
    provideAnimations(),
    importProvidersFrom(
      MatDialogModule,
      MatSnackbarModule,
      NgxsModule.forRoot([WidgetsState, TranslationState, LayoutState], {
        developmentMode: !isDevMode(),
        compatibility: {
          strictContentSecurityPolicy: true,
        },
      }),
      NgxsStoragePluginModule.forRoot({
        key: [
          'translation.languageCode',
          'layout.activeClientId',
          'menu',
          'filters.startDate',
          'filters.endDate',
          'widgets.outputs.filter_startDate.value',
          'widgets.outputs.filter_endDate.value',
        ],
      }),
      NgxsRouterPluginModule.forRoot(),
      isDevMode() ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
      isDevMode() ? NgxsLoggerPluginModule.forRoot() : []
    ),
  ],
}).catch(err => console.error(err));
