<mat-chip
  [style.paddingLeft]="paddingLeft"
  [style.paddingRight]="paddingRight"
  [style.paddingTop]="paddingTop"
  [style.paddingBottom]="paddingBottom"
  [style.marginTop]="marginTop"
  [style.marginLeft]="marginLeft"
  [style.marginBottom]="marginBottom"
  [style.marginRight]="marginRight"
  [matTooltip]="tooltipText"
  [matTooltipPosition]="tooltipPosition"
  [selected]="input.highlighted | parse: context | async"
  [color]="color"
  [ngClass]="classes"
  (removed)="handleRemoved()"
>
  <ng-container
    *ngFor="let widget of input.children"
    [dynamicWidget]="widget"
    [context]="context"
  />
  @if (
    isExpression(input.onRemove)
      ? (input.onRemove | parse: context | async)
      : input.onRemove
  ) {
    @if (input.removeButton) {
      <tb-button
        [input]="input.removeButton"
        [context]="context"
        matChipRemove
      />
    } @else {
      <mat-icon matChipRemove>cancel</mat-icon>
    }
  }
</mat-chip>
